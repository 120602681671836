import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button", "checkbox"];
  static values = {
    newInspectionUrl: String,
    newFactoryStandardAttachmentUrl: String
  }

  connect() {
    this.updateSubmitButton();
  }

  visitNewInspectionUrl() {
    Turbo.visit(this.getNewInspectionUrlWithParams());
  }

  visitNewFactoryStandardAttachmentUrl() {
    Turbo.visit(this.getNewFactoryStandardAttachmentUrlWithParams());
  }

  getNewInspectionUrlWithParams() {
    const url = new URL(this.newInspectionUrlValue);

    this.checkedCheckboxes().map(element => {
      url.searchParams.append("inspection[factory_standard_id][]", element.value);
    })

    return url.toString();
  }

  getNewFactoryStandardAttachmentUrlWithParams() {
    const url = new URL(this.newFactoryStandardAttachmentUrlValue);

    this.checkedCheckboxes().map(element => {
      url.searchParams.append("inspections_factory_standard_attachment[factory_standard_id][]", element.value);
    })

    return url.toString();
  }

  checkedCheckboxes() {
    return this.checkboxTargets.filter(element => element.checked);
  }

  updateSubmitButton() {
    if (this.someCheckboxChecked()) {
      this.buttonTargets.forEach(element => element.removeAttribute("disabled"));
    } else {
      this.buttonTargets.forEach(element => element.setAttribute("disabled", "disabled"));
    }
  }

  someCheckboxChecked() {
    return this.checkboxTargets.some(element => element.checked);
  }
}
